// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aki6dkfJokIrfxtOV1KX{display:flex;justify-content:center;align-items:center;box-sizing:border-box;width:100vw;height:100vh}.iwssPev494SteUV9hP7L{display:flex;flex-direction:column;box-sizing:border-box;width:100vw;height:100vh}.HzJ6EbUhynbMKaJzVQk7{flex-grow:2;background:#fff}.pcTggbS2ZYwK66NgaIxn{box-sizing:border-box;width:100%;padding:40px 24px;margin:0 auto;max-width:728px}`, "",{"version":3,"sources":["webpack://./src/layouts/AppLayout/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,WAAA,CACA,YAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,qBAAA,CACA,WAAA,CACA,YAAA,CAGF,sBACE,WAAA,CACA,eAAA,CAGF,sBACE,qBAAA,CACA,UAAA,CACA,iBAAA,CACA,aAAA,CACA,eAAA","sourcesContent":["@import \"@styles/variables\";\n.spinnerWrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  box-sizing: border-box;\n  width: 100vw;\n  height: 100vh;\n}\n\n.root {\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n  width: 100vw;\n  height: 100vh;\n}\n\n.content {\n  flex-grow: 2;\n  background: #FFFFFF;\n}\n\n.container {\n  box-sizing: border-box;\n  width: 100%;\n  padding: 40px 24px;\n  margin: 0 auto;\n  max-width: calc(680px + 48px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerWrapper": `aki6dkfJokIrfxtOV1KX`,
	"root": `iwssPev494SteUV9hP7L`,
	"content": `HzJ6EbUhynbMKaJzVQk7`,
	"container": `pcTggbS2ZYwK66NgaIxn`
};
export default ___CSS_LOADER_EXPORT___;
