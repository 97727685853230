import { takeEvery, all, call } from 'redux-saga/effects';
import { authActions } from '@bus/auth/actions';

// eslint-disable-next-line prettier/prettier
// import {
//   signUp,
//   verifyEmail,
//   authenticate,
//   logIn,
//   logout,
//   resetPassword,
//   forgotPassword,
//   forgotPasswordValidateOtp,
// } from './workers';
import { signUp } from './workers/signUp';
import { verifyEmail } from './workers/verifyEmail';
import { authenticate } from './workers/authenticate';
import { logIn } from './workers/logIn';
import { logout } from './workers/logout';
import { forgotPassword } from './workers/forgotPassword';
import { forgotPasswordValidateOtp } from './workers/forgotPasswordValidateOtp';
import { resetPassword } from './workers/resetPassword';

// IMPORTS

function* watchSignUp() {
  yield takeEvery(authActions.signUp.type, signUp);
}

function* watchVerifyEmail() {
  yield takeEvery(authActions.verifyEmail.type, verifyEmail);
}

function* watchAuthenticate() {
  yield takeEvery(authActions.authenticate.type, authenticate);
}

function* watchLogIn() {
  yield takeEvery(authActions.logIn.type, logIn);
}

function* watchLogout() {
  yield takeEvery(authActions.logout.type, logout);
}
function* watchForgotPassword() {
  yield takeEvery(authActions.forgotPassword.type, forgotPassword);
}
function* watchForgotPasswordValidateOtp() {
  yield takeEvery(
    authActions.forgotPasswordValidateOtp.type,
    forgotPasswordValidateOtp,
  );
}
function* watchResetPassword() {
  yield takeEvery(authActions.resetPassword.type, resetPassword);
}

// WATCHERS
export function* watchAuth() {
  // eslint-disable-next-line prettier/prettier
  yield all([
    call(watchSignUp),
    call(watchVerifyEmail),
    call(watchAuthenticate),
    call(watchLogIn),
    call(watchLogout),
    call(watchForgotPassword),
    call(watchForgotPasswordValidateOtp),
    call(watchResetPassword),
  ]);
}
