import { takeEvery, all, call } from 'redux-saga/effects';
import { profileActions } from '@bus/profile/actions';

// eslint-disable-next-line prettier/prettier
import { fetchProfile } from './workers';

// IMPORTS

function* watchFetchProfile() {
  yield takeEvery(profileActions.fetchProfile.type, fetchProfile);
}

// WATCHERS
export function* watchProfile() {
  // eslint-disable-next-line prettier/prettier
  yield all([call(watchFetchProfile)]);
}
