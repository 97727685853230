import { apply, call, put } from 'redux-saga/effects';

import { api } from '@REST/api';
import { FormTypes } from '@core/Form/typedefs';

import { VerifyEmailActionPayload } from '../../typedefs';
import { FORM_ERROR } from 'final-form';
import { authActions } from '@bus/auth/actions';
import { uiActions } from '@bus/ui/actions';

export function* verifyEmail({
  payload,
  meta: { resolve, reject },
}: FormTypes.PayloadActionWithPromiseMeta<VerifyEmailActionPayload>) {
  try {
    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `auth/verify-email-otp`, body, unsafe: true },
    ]);

    if (!response.ok) {
      const errors: FormTypes.ServerFormErrors = yield call([response, 'json']);
      reject({
        [FORM_ERROR]:
          'Pin code verification failed. Please review your pin and resubmit.',
      });

      return new Error();
    }

    resolve();
  } catch (e) {
    console.warn(e);
  }
}
