import * as effects from 'redux-saga/effects';
import { authActions } from '@bus/auth/actions';
import { profileActions } from '@bus/profile/actions';
import { logout as logoutRequest } from '@REST/api';
import { call } from 'redux-saga/effects';

export function* logout() {
  try {
    // yield call(logoutRequest);
    yield effects.put(authActions.clearData());
    yield effects.put(profileActions.clearProfile());
  } catch (e) {
    console.warn(e);
  }
}
