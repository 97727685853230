import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { authActions } from '@bus/auth/actions';
import { getIsAuthenticated, getIsInitialize } from '@bus/auth/selectors';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import SpinnerCircle from '@components/common/SpinnerCircle';

const Routers = () => {
  const isInitialised = useSelector(getIsInitialize);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.authenticate());
  }, [dispatch]);

  return isInitialised ? (
    <BrowserRouter basename={'/'}>
      <Suspense fallback={<>...loading</>}>
        <Routes>
          {isAuthenticated ? (
            <Route path={`/*`} element={<PrivateRoutes />} />
          ) : (
            <Route path={`/*`} element={<PublicRoutes />} />
          )}
        </Routes>
      </Suspense>
    </BrowserRouter>
  ) : (
    <SpinnerCircle />
  );
};

export default Routers;
