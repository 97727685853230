import { FORM_ERROR } from 'final-form';
import { apply, call, put } from 'redux-saga/effects';

import { api } from '@REST/api';
import { FormTypes } from '@core/Form/typedefs';

import { ForgotPasswordValidateOtpActionPayload } from '../../typedefs';
import { uiActions } from '@bus/ui/actions';

export function* forgotPasswordValidateOtp({
  payload,
  meta: { resolve, reject },
}: FormTypes.PayloadActionWithPromiseMeta<ForgotPasswordValidateOtpActionPayload>) {
  try {
    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `auth/reset-password/validate`, body, unsafe: true },
    ]);

    if (!response.ok) {
      reject({
        [FORM_ERROR]:
          'Pin code verification failed. Please review your pin and resubmit.',
      });

      return new Error();
    }

    resolve();
  } catch (e) {
    console.warn(e);
  }
}
