import prepareActions from '@helpers/prepareActions';
import { createAction } from '@reduxjs/toolkit';

import authSlice from './slice';

export const authActions = {
  ...authSlice.actions,
  signUp: createAction('auth/signUp', prepareActions.movePromiseToMeta),
  verifyEmail: createAction(
    'auth/verifyEmail',
    prepareActions.movePromiseToMeta,
  ),
  authenticate: createAction('auth/authenticate'),
  logIn: createAction('auth/logIn', prepareActions.movePromiseToMeta),
  logout: createAction('auth/logout'),
  forgotPassword: createAction(
    'auth/forgotPassword',
    prepareActions.movePromiseToMeta,
  ),
  forgotPasswordValidateOtp: createAction(
    'auth/forgotPasswordValidateOtp',
    prepareActions.movePromiseToMeta,
  ),
  resetPassword: createAction(
    'auth/resetPassword',
    prepareActions.movePromiseToMeta,
  ),
  // INJECT
};
