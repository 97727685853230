/* eslint-disable prettier/prettier */
import layouts from '@layouts/index';
import { commonRoutes } from '@pages/common/routes';
import { book } from '@routes/book';
import { AppRoute } from '@setup/typedefs';
import { lazy } from 'react';

// IMPORTS
const ListingsPage = lazy(() => import('@pages/private/ListingsPage'));

export const privateRoutes: AppRoute<any>[] = [
  ...commonRoutes,
  ...[
    {
      path: `${book.myListings}`,
      Component: ListingsPage,
      Layout: layouts.AppLayout,
      layoutProps: {},
    },
    // INJECT
  ],
];
