import AppLayout from './AppLayout';
import AuthLayout from './AuthLayout';
import CommonLayout from './CommonLayout';

// IMPORTS
const Layouts = {
  AppLayout,
  AuthLayout,
  CommonLayout,
  // INJECT
};

export default Layouts;
