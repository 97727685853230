import { FORM_ERROR } from 'final-form';
import { apply, call, put } from 'redux-saga/effects';

import { api } from '@REST/api';
import { FormTypes } from '@core/Form/typedefs';

import { ForgotPasswordActionPayload } from '../../typedefs';
import { uiActions } from '@bus/ui/actions';

export function* forgotPassword({
  payload,
  meta: { resolve, reject },
}: FormTypes.PayloadActionWithPromiseMeta<ForgotPasswordActionPayload>) {
  try {
    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `auth/forgot-password`, body, unsafe: true },
    ]);

    if (!response.ok) {
      reject({
        [FORM_ERROR]:
          'Unable to process your password recovery request. Please try again later.',
      });

      return new Error();
    }

    resolve();
  } catch (e) {
    console.warn(e);
  }
}
