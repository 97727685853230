import React from 'react';
import { ModalTypes } from '@core/Modal/types';
import Button from '@components/common/Button';
import icon from '@assets/notification-success.svg';
import closeIcon from '@assets/close.svg';
import classes from './styles.module.scss';

type AlertProps = {
  text: string;
  cancelButton: {
    text: string;
  };
  confirmButton: {
    text: string;
  };
};

export const Alert: React.FC<ModalTypes.ModalComponentProps<AlertProps>> = ({
  closeFn,
  text,
}) => {
  const closeModal = () => {
    closeFn();
  };

  return (
    <div className={classes.root}>
      <button className={classes.closeBtn} onClick={closeModal}>
        <img src={closeIcon} alt="Close" height={20} width={20} />
      </button>
      <img className={classes.icon} src={icon} alt={'Notification'} />

      <h3>Co-op fee</h3>
      <p>Do you want to save your co-op fee?</p>
      <div className={classes.btns}>
        <Button label={'Save'} onClick={closeModal} />
        <Button label={'Cancel'} color={'secondary'} onClick={closeModal} />
      </div>
    </div>
  );
};
