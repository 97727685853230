import { call, put } from 'redux-saga/effects';

import {
  refreshToken,
  accessToken,
  verifyToken,
  removeQuotes,
} from '@REST/api';

import { authActions } from '../../actions';

export function* authenticate() {
  try {
    const tokenAccess: string = yield call(accessToken.get);
    if (tokenAccess) {
      const valid: boolean = yield call(verifyToken, tokenAccess);
      if (!valid) {
        const newToken: string = yield call(refreshToken);
        console.log(newToken);
        if (newToken) {
          console.log('case 1');
          yield call(accessToken.set, removeQuotes(newToken));
          yield put(authActions.isAuthenticatedTrue());
        } else {
          console.log('case 2');
          yield put(authActions.logout());

          return new Error('Your session is expired.');
        }
      } else {
        yield put(authActions.isAuthenticatedTrue());
      }
    }
  } catch (e) {
    console.warn(e);
  } finally {
    yield put(authActions.initialize());
  }
}
