import clsx from 'clsx';
import React from 'react';
import classes from './styles.module.scss';

type SpinnerCircleProps = {
  color?: string;
  animationDuration?: string | number;
  size?: string;
  className?: any;
  style?: any;
};
const SpinnerCircle: React.FC<SpinnerCircleProps> = ({
  color,
  animationDuration = 1.4,
  size = '70px',
  className,
  style = {},
}) => {
  const computedStyle = {
    height: size,
    width: size,
    borderTopColor: color,
    animationDuration: `${animationDuration}s`,
    ...style,
  };

  if (color) {
    computedStyle.borderLeftColor = color;
  }

  return (
    <div
      className={clsx([classes.root, className && className])}
      style={computedStyle}
    />
  );
};

export default SpinnerCircle;
