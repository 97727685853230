import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { privateRoutes } from '@pages/private/routes';
import AuthLayout from '@layouts/AuthLayout';
import { book } from '@routes/book';

// IMPORTS
const PrivateRoutes = () => {
  return (
    <Routes>
      {privateRoutes.map(({ path, Component, Layout, layoutProps }) => (
        <Route element={<Layout {...layoutProps} />} key={path}>
          <Route path={path} element={<Component />} />
        </Route>
      ))}
      <Route element={<AuthLayout />}>
        <Route
          path={'/*'}
          element={<Navigate replace to={book.myListings} />}
        />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
