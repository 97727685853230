import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { Middleware, configureStore } from '@reduxjs/toolkit';

import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const middleware: Middleware[] = [sagaMiddleware];

export const Store = (initialState: any) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: {
          ignoredPaths: ['modal'],
          ignoredActionPaths: ['meta', 'payload'],
          ignoredActions: ['persist/PERSIST'],
        },
      }).concat(sagaMiddleware),
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};

export const createStore = (initialState = {}) => Store(initialState);
