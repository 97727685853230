import React from 'react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createStore } from '@setup/configureStore';

import { registeredModals } from '@components/modals/registeredModals';
import { Modals } from '@core/Modal';
import Routers from './routes';

import '@styles/reset.css';
import '@styles/styles.scss';

export const { store, persistor } = createStore();

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <>
          <Modals registeredModals={registeredModals} />
          <Routers />
        </>
      </PersistGate>
    </Provider>
  );
}

export default App;
