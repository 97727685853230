import React from 'react';
import classes from './styles.module.scss';
import clsx from 'clsx';
import loaderWhite from '@assets/loader-dashed-white.svg';
import loaderBlue from '@assets/loader-dashed-blue.svg';
import checkWhiteIcon from '@assets/check-white.svg';

const icons = {
  primary: loaderWhite,
  secondary: loaderBlue,
};

type ButtonProps = {
  label: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  loading?: boolean;
  disabled?: boolean;
  success?: boolean;
  successText?: string;
  color?: 'primary' | 'secondary';
  endAdornment?: any;
  onClick?: () => void;
  className?: any;
  style?: any;
};

const Button: React.FC<ButtonProps> = ({
  label,
  type = 'button',
  loading,
  disabled,
  success,
  successText,
  color = 'primary',
  endAdornment,
  onClick,
  className,
  style,
}) => {
  const handleClick = () => {
    if (disabled || loading || successText) return;

    onClick && onClick();
  };

  return (
    <button
      type={success ? 'button' : type}
      disabled={successText ? false : disabled}
      onClick={handleClick}
      className={clsx([
        classes.root,
        classes[color],
        loading && classes.loading,
        success && classes.success,
        className,
      ])}
      style={style}>
      <span>{successText && success ? successText : label}</span>
      {endAdornment && !loading && !success && (
        <div className={classes.endAdornment}>{endAdornment}</div>
      )}

      {success && !loading && (
        <div className={classes.endAdornment}>
          <img src={checkWhiteIcon} alt={'Success'} width={20} height={20} />
        </div>
      )}

      {loading && (
        <img className={classes.spinner} src={icons[color]} alt={'Loading'} />
      )}
    </button>
  );
};

export default Button;
