import * as effects from 'redux-saga/effects';

// eslint-disable-next-line prettier/prettier
// import { } from './workers';

// IMPORTS

// function* watch() {
// yield effects.takeEvery(, );
// }

// WATCHERS
export function* watchUi() {
  // eslint-disable-next-line prettier/prettier
  yield effects.all([
    // effects.call(),
    // INJECT
  ]);
}
