import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalTypes } from '@core/Modal/types';

import { modalActions } from './state/actions';
import { getModalData } from './state/selectors';
import { getId } from '@helpers/getId';
import Modal from 'react-responsive-modal';
import './styles.scss';

type ModalsProps = {
  registeredModals: ModalTypes.RegisteredModals;
};

export const Modals: React.FC<ModalsProps> = ({ registeredModals }) => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const data = Object.values(modalData);

  const close = (name: any) => () => {
    dispatch(modalActions.closeModal(name));
  };

  return (
    <>
      {data.map(({ component, ...rest }, index) => {
        const closeFn = close(component);

        return (
          <Modal
            key={getId()}
            onClose={closeFn}
            open={true}
            showCloseIcon={false}
            focusTrapped={false}
            classNames={{
              root: `reactModal`,
              overlay: `reactModalOverlay`,
              modalContainer: `reactModalContainer`,
              modal: `reactModalModal`,
            }}>
            {React.createElement(registeredModals[component], {
              ...rest,
              closeFn,
            })}
          </Modal>
        );
      })}
    </>
  );
};
