import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

export type CommonLayoutProps = any;

const CommonLayout: React.FC<CommonLayoutProps> = () => {
  return (
    <Suspense fallback={<>...loading</>}>
      <Outlet />
    </Suspense>
  );
};

export default CommonLayout;
