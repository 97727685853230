import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import classes from './styles.module.scss';
import SpinnerCircle from '@components/common/SpinnerCircle';
import logo from '@assets/logo-white.svg';

export type AuthLayoutProps = any;

const AuthLayout: React.FC<AuthLayoutProps> = () => {
  return (
    <Suspense
      fallback={
        <div className={classes.spinnerWrapper}>
          <SpinnerCircle />
        </div>
      }>
      <div className={classes.root}>
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
        <div className={classes.decoration}>
          <div className={classes.background}>
            <img className={classes.logo} src={logo} alt="What`s my com" />
            <p className={classes.name}>
              <b>whats</b>
              <span>mycom</span>
            </p>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default AuthLayout;
