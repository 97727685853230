import { FORM_ERROR } from 'final-form';
import { apply, call, put } from 'redux-saga/effects';

import { api } from '@REST/api';
import { FormTypes } from '@core/Form/typedefs';

import { LogInActionPayload } from '../../typedefs';
import { authActions } from '@bus/auth/actions';

export function* logIn({
  payload,
  meta: { resolve, reject },
}: FormTypes.PayloadActionWithPromiseMeta<LogInActionPayload>) {
  try {
    const body: string = yield call(JSON.stringify, payload);

    const response: Response = yield apply(api, api.post, [
      { endpoint: `auth/login`, body, unsafe: true },
    ]);

    if (!response.ok) {
      reject({
        [FORM_ERROR]: 'Please check your credentials',
      });

      return new Error();
    }

    const { token } = yield call([response, 'json']);

    window.localStorage.setItem('accessToken', JSON.stringify(token));
    resolve();

    yield put(authActions.isAuthenticatedTrue());
  } catch (e) {
    console.warn(e);
  }
}
