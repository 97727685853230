import React from 'react';
import classes from './styles.module.scss';
import logo from '@assets/logo.svg';
import exitIcon from '@assets/exit.svg';
import { useDispatch } from 'react-redux';
import { authActions } from '@bus/auth/actions';
import { useNavigate } from 'react-router-dom';
import { book } from '@routes/book';

const PrivateHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(authActions.logout());
    navigate(book.logIn);
  };

  return (
    <div className={classes.root}>
      <div className={classes.logoBlock}>
        <img className={classes.logo} src={logo} alt="What`s my com" />
        <p className={classes.name}>
          <b>whats</b>
          <span>mycom</span>
        </p>
      </div>

      <button className={classes.logOutBtn} onClick={logOut}>
        Log Out
        <img className={classes.logo} src={exitIcon} alt="Log out" />
      </button>
    </div>
  );
};

export default PrivateHeader;
