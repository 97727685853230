import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import SpinnerCircle from '@components/common/SpinnerCircle';
import classes from './styles.module.scss';
import PrivateHeader from '@components/common/PrivateHeader';

export type AppLayoutProps = any;

const AppLayout: React.FC<AppLayoutProps> = () => {
  return (
    <Suspense
      fallback={
        <div className={classes.spinnerWrapper}>
          <SpinnerCircle />
        </div>
      }>
      <div className={classes.root}>
        <PrivateHeader />
        <div className={classes.content}>
          <div className={classes.container}>
            <Outlet />
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default AppLayout;
