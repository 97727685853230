// eslint-disable-next-line @typescript-eslint/no-unused-vars,prettier/prettier
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line prettier/prettier
import { UiState } from './typedefs';

const initialState: UiState = {
  isFetching: true,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    startFetching(state) {
      state.isFetching = true;
    },
    stopFetching(state) {
      state.isFetching = false;
    },
    // INJECT
  },
});

export default uiSlice;
